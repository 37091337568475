import { Box, Typography, Stack, SxProps, Theme } from "@mui/material";

import SearchBar from "./SearchBar";
import PageHeaderText from "@/components/text/PageHeaderText";
import RefreshButton from "@/components/buttons/RefreshButton";
import CreateButton from "@/components/buttons/CreateButton";

export interface IInheritedTableHeaderProps {
  headerText?: string;
  subHeader?: string | JSX.Element;
  actionBtnOverride?: JSX.Element | JSX.Element[];
  onCreateBtnClick?: (type?: string) => void;
  onRefreshBtnClick?: () => void;
}

export interface ITableHeaderProps extends IInheritedTableHeaderProps {
  entityName: string;
  onSearchChange: (event: string) => void;
  sx?: SxProps<Theme>;
}

const FOOTER_HEIGHT = "36px";

const TableHeader: React.FunctionComponent<ITableHeaderProps> = (props) => {
  return (
    <Box>
      {props.headerText && (
        <PageHeaderText sx={{ pb: 1 }}>{props.headerText}</PageHeaderText>
      )}

      {props.subHeader && (
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Typography sx={{ fontSize: 20, mr: 0.5 }}>💡</Typography>
          <Typography sx={{ fontSize: 15, color: "text.secondary" }}>
            {props.subHeader}
          </Typography>
        </Stack>
      )}

      <Stack
        direction="row"
        sx={{
          mt: 4,
          alignItems: "center",
          justifyContent: "space-between",
          height: FOOTER_HEIGHT,
        }}
      >
        <SearchBar
          onChange={props.onSearchChange}
          sx={{ height: FOOTER_HEIGHT }}
        />

        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {props.onRefreshBtnClick && (
            <RefreshButton
              onClick={props.onRefreshBtnClick}
              sx={{ mr: 2 }}
              iconSx={{ fontSize: 22 }}
            />
          )}

          {props.actionBtnOverride ||
            (props.onCreateBtnClick && (
              <CreateButton
                onClick={props.onCreateBtnClick}
                sx={{ height: "100%" }}
              >
                Create {props.entityName}
              </CreateButton>
            ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TableHeader;
