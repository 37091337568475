import TabletMacIcon from "@mui/icons-material/TabletMac";
import MessageIcon from "@mui/icons-material/Message";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";

import PageHeaderText from "@/components/text/PageHeaderText";
import { useState } from "react";
import AppTabGroup from "@/components/tabs/AppTabGroup";
import AppTab from "@/components/tabs/AppTab";
import TabletScreensSettings from "./tabletScreens";
import RewardsAppMessagingSettings from "./messaging";
import useRewardsAppSettings from "./useRewardsAppSettings";
import { Collapse, Stack, Typography } from "@mui/material";
import { OutlinedGradientButton, SaveButton } from "@/components/buttons";
import { getPrimaryGradient } from "@/shared/utils/ui";
import moment from "moment";

interface IRewardsAppSettingsProps {}

const RewardsAppSettings: React.FC<IRewardsAppSettingsProps> = (props) => {
  const { hasChanged, save, reset, tabletScreens } = useRewardsAppSettings();

  const tabs = [
    {
      title: "Tablet Screens",
      icon: TabletMacIcon,
      iconSx: {
        transform: "rotate(-90deg)",
      },
      component: <TabletScreensSettings {...tabletScreens} />,
    },
    {
      title: "Messaging",
      icon: MessageIcon,
      component: <RewardsAppMessagingSettings sx={{ mt: 3 }} />,
    },
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <FullPageBase>
      <Stack sx={{ position: "relative", height: "100%" }}>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <PageHeaderText>Rewards App Settings</PageHeaderText>

          <OutlinedGradientButton
            onClick={() => {
              window.open("https://instore.askalex.co", "_blank")?.focus();
            }}
            icon={<OpenInNewIcon sx={{ fontSize: 18 }} />}
          >
            Go to Rewards App
          </OutlinedGradientButton>
        </Stack>

        <Typography sx={{ mt: 1, fontSize: 13, color: "text.secondary" }}>
          Last Updated:{" "}
          <Typography sx={{ display: "inline", fontSize: "inherit" }}>
            {moment(tabletScreens.updatedAt)
              ?.local()
              ?.format("MMM. DD, YYYY @ hh:mm a")
              ?.toString()}
          </Typography>
        </Typography>

        <AppTabGroup sx={{ mt: 2 }}>
          {tabs.map((tab, i) => {
            return (
              <AppTab
                title={tab.title}
                icon={tab.icon}
                iconSx={tab.iconSx}
                onClick={() => setSelectedTabIndex(i)}
                isSelected={selectedTabIndex === i}
              />
            );
          })}
        </AppTabGroup>

        {tabs[selectedTabIndex].component}

        {/* TODO - componentize this */}
        <Stack
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Collapse in={hasChanged}>
            <Stack
              sx={{
                background: getPrimaryGradient(165),
                borderRadius: "200px",
                height: "50px",
                width: "100%",
                boxShadow: (theme) => `0 0 3px ${theme.palette.primary.main}`,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  bgcolor: "grey.100",
                  borderRadius: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "calc(100% - 4px)",
                  height: "calc(100% - 4px)",
                  mt: "2px",
                  ml: "2px",
                }}
                spacing={4}
              >
                <Typography
                  onClick={() => reset()}
                  sx={{
                    cursor: "pointer",
                    fontSize: 15,
                    fontWeight: "bold",
                    color: "text.secondary",
                    "&:hover": {
                      color: "text.primary",
                    },
                  }}
                >
                  Reset
                </Typography>

                <SaveButton onClick={() => save()} />
              </Stack>
            </Stack>
          </Collapse>
        </Stack>
      </Stack>
    </FullPageBase>
  );
};

export default RewardsAppSettings;
