import { TableRow } from "@mui/material";
import moment from "moment";

import { Contact } from "@alex/types";

import {
  ActionsTableCell,
  AppTableCell,
  INoEntityClickTableRowProps,
} from "@/components/tables";
import {
  getFullNameDisplay,
  getPhoneNumberDisplay,
} from "@/shared/utils/formatters";

export interface IListContactRowProps
  extends Contact,
    INoEntityClickTableRowProps {}

const ListContactRow: React.FunctionComponent<IListContactRowProps> = (
  props,
) => {
  return (
    <TableRow key={props.ID}>
      <AppTableCell emphasize>
        {getFullNameDisplay(props.firstName, props.lastName) || "no name"}
      </AppTableCell>

      <AppTableCell emphasize>
        {getPhoneNumberDisplay(props.phoneNumber)}
      </AppTableCell>

      <AppTableCell>
        {/* TODO - need to change this to IListContact */}
        {/* @ts-ignore */}
        {moment(props["addedAt"]).local().format("ddd, MMM D, yyyy")}
      </AppTableCell>

      <ActionsTableCell
        onClick={(e) => {
          if (props.openActionsPopover) {
            props.openActionsPopover(props.ID, props.firstName, e);
          }
        }}
      />
    </TableRow>
  );
};

export default ListContactRow;
