import { useState, useEffect, useCallback } from "react";

import { AppFile, List } from "@alex/types";

import { IBaseFormProps, FormWrapper } from "@/components/forms";
import { AppTextField, AppFileUpload } from "@/components/inputs";
import { useAppDispatch } from "@/app/state/hooks";
import { ValidationResponse } from "@/shared";
import {
  getListByID,
  getListNameIsTaken,
  createList,
  updateList,
} from "../../slice";
import { validateNotEmpty } from "@/shared/utils/validators";
import { addNewToast } from "@/services/ui/uiSlice";

const DEFAULT_HEADER_TEXT = "Upload a List";

export interface IListsFormProps extends IBaseFormProps {
  listID?: string;
}

const ListsForm: React.FunctionComponent<IListsFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const isUpdate = !!props.listID;

  const [headerText, setHeaderText] = useState(DEFAULT_HEADER_TEXT);
  const [secondaryHeaderText, setSecondaryHeaderText] = useState<
    string | undefined
  >();

  const [name, setName] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState<string | undefined>("");

  const [description, setDescription] = useState<string | undefined>("");

  const [uploadedFile, setUploadedFile] = useState<AppFile | undefined>();

  useEffect(() => {
    if (props.listID) {
      dispatch(getListByID(props.listID)).then((res) => {
        if (res.payload) {
          const savedList = res.payload as List;
          setName(savedList.name);
          setDescription(savedList.description);

          setHeaderText("Edit List");
          setSecondaryHeaderText(savedList.name);
        } else {
          setHeaderText(DEFAULT_HEADER_TEXT);
          setSecondaryHeaderText(undefined);
        }
      });
    }
  }, [dispatch, props.listID]);

  const validateName = useCallback(async (): Promise<ValidationResponse> => {
    const emptyRes = validateNotEmpty(name);

    if (!emptyRes.isValid) {
      setNameErrorMsg(emptyRes.errorMsg || "");
      return emptyRes;
    }

    const isTakenRes = await dispatch(getListNameIsTaken(name));
    const isValid = !isTakenRes.payload; // valid if not taken

    const errorMsg = isValid ? "" : "list already exists";
    setNameErrorMsg(errorMsg);

    return {
      isValid,
      errorMsg,
    };
  }, [name, dispatch]);

  async function handleSaveBtnClick() {
    // TODO - componentize this a little better
    if (isUpdate && props.listID) {
      await dispatch(
        updateList({
          id: props.listID,
          update: {
            description,
          },
        }),
      );
    } else {
      const segNameValid = await validateName();
      if (!segNameValid.isValid) {
        dispatch(addNewToast({ text: "womp womp", severity: "error" }));
        return;
      }

      await dispatch(
        createList({
          name,
          description,
        }),
      );

      dispatch(
        addNewToast({
          text: "Saved list",
          severity: "success",
        }),
      );
    }

    props.onClose();
  }

  return (
    <FormWrapper
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      isUpdate={isUpdate}
      onSaveBtnClick={handleSaveBtnClick}
      onCancelBtnClick={props.onClose}
    >
      <AppTextField
        label="Name"
        value={name}
        errorMsg={nameErrorMsg}
        onChange={(e) => setName(e.target.value)}
        onBlur={(e) => validateName()}
        disabled={isUpdate}
        sx={{
          width: "500px",
        }}
      />

      <AppTextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        sx={{
          width: "500px",
        }}
      />

      <AppFileUpload
        label="File"
        value={uploadedFile}
        onChange={(data) => {
          setUploadedFile(data);
        }}
        sx={{ width: "700px", height: "min(300px, 40vh)" }}
      />
    </FormWrapper>
  );
};

export default ListsForm;
