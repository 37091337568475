import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { Contact, ContactUpdate } from "@alex/types";

import { apiGet, apiPost, apiPut } from "@/services/api/apiSlice";
import {
  InteractionSummary,
  Message,
  SetSelectedInteractionResponse,
  MessageDisplayTemplateOptions,
  MessageDisplayItem,
  UncreatedMessage,
  MessageWithSenderInitials,
} from "./types";
import { RootState } from "@/app/state/store";
import { configureNewAPIToken } from "@/features/auth/authSlice";
import { getNameInitials } from "@/shared/utils/formatters";

interface MessagesState {
  interactionSummaries: InteractionSummary[] | null;
  selectedInteraction: {
    contact: Contact;
    messages: MessageWithSenderInitials[];
  } | null;
}

const initialState: MessagesState = {
  interactionSummaries: null,
  selectedInteraction: null,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setInteractionSummaries(
      state,
      action: PayloadAction<InteractionSummary[]>,
    ) {
      state.interactionSummaries = action.payload;
    },
    appendToMessages(state, action: PayloadAction<Message>) {
      state.selectedInteraction?.messages.push(action.payload);
    },
    updateInteractionSummariesWithNewMessage(
      state,
      action: PayloadAction<Message>,
    ) {
      const newMsg = action.payload;
      const contactID = newMsg.contactID;

      if (!state.interactionSummaries) {
        // potentially make a function that will do this
        let newInteraction: InteractionSummary = {
          businessID: newMsg.businessID,
          contactID: newMsg.contactID,
          phoneNumber: newMsg.fromNum,
          firstName: "",
          lastName: "",
          lastMsg: newMsg,
          numUnread: newMsg.isRead ? 0 : 1,
        };

        state.interactionSummaries = [newInteraction];
        return;
      }

      let ogSummaries = state.interactionSummaries;

      const targetIndex = ogSummaries?.findIndex(
        (el) => el.contactID === contactID,
      );

      if (targetIndex === -1) {
        // this will happen when this function is called by websocket middleware
        // update this with the actual data from the
        let newInteraction: InteractionSummary = {
          businessID: newMsg.businessID,
          contactID: newMsg.contactID,
          phoneNumber: newMsg.fromNum,
          firstName: "",
          lastName: "",
          lastMsg: newMsg,
          numUnread: newMsg.isRead ? 0 : 1,
        };

        state.interactionSummaries.unshift(newInteraction);
      } else {
        let targetInteraction = ogSummaries[targetIndex];
        targetInteraction.lastMsg = newMsg;

        const newSummaries = [targetInteraction].concat(
          ogSummaries.slice(0, targetIndex),
          ogSummaries.slice(targetIndex + 1),
        );

        state.interactionSummaries = newSummaries;
      }
    },
    updateInteractionSummariesWithReadMsgs(
      state,
      action: PayloadAction<Message[]>,
    ) {
      const readMsgs = action.payload;

      if (!readMsgs.length || !state.interactionSummaries) {
        return;
      }

      let counts: { [key: string]: number } = {};

      for (const msg of readMsgs) {
        const contactID = msg.contactID;

        if (contactID in counts) {
          counts[contactID] += 1;
        } else {
          counts[contactID] = 1;
        }
      }

      let ogSummaries = state.interactionSummaries.slice();

      for (let i = 0; i < ogSummaries.length; i++) {
        if (ogSummaries[i].contactID in counts) {
          ogSummaries[i].numUnread -= counts[ogSummaries[i].contactID];
        }
      }
    },
    updateInteractionSummariesWithContact(
      state,
      action: PayloadAction<Contact>,
    ) {
      const updatedContact = action.payload;

      if (!updatedContact || !state.interactionSummaries) {
        return;
      }

      const targetIndex = state.interactionSummaries.findIndex(
        (el) => el.contactID === updatedContact.ID,
      );

      if (targetIndex !== -1) {
        const updatedInteraction: InteractionSummary = {
          ...state.interactionSummaries[targetIndex],
          firstName: updatedContact.firstName,
          lastName: updatedContact.lastName,
        };

        state.interactionSummaries[targetIndex] = updatedInteraction;
      }
    },
    updateSelectedInteractionMessagesWithReadMsgs(
      state,
      action: PayloadAction<Message[]>,
    ) {
      if (!action.payload || !state.selectedInteraction?.messages) {
        return;
      }

      const readMsgs = action.payload;

      const readMsgIDs = new Set<string>();

      readMsgs.forEach((msg) => {
        readMsgIDs.add(msg.ID);
      });

      let msgsCopy = state.selectedInteraction.messages.slice();

      msgsCopy.forEach((msg) => {
        if (readMsgIDs.has(msg.ID)) {
          msg.isRead = true;
        }
      });

      state.selectedInteraction.messages = msgsCopy;
    },
  },
  extraReducers(builder) {
    builder.addCase(getInteractionSummaries.fulfilled, (state, action) => {
      state.interactionSummaries = action.payload;
    });
    builder.addCase(getInteractionSummaries.rejected, (state, action) => {
      console.log("Failure");
    });
    builder.addCase(setSelectedInteraction.fulfilled, (state, action) => {
      if (action.payload) {
        state.selectedInteraction = {
          contact: action.payload.contact,
          messages: action.payload.messages,
        };
      } else {
        state.selectedInteraction = null;
      }
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      if (!action || !action.payload) {
        // show the user an error
        return;
      }

      const reducerPayload = {
        payload: action.payload,
        type: action.type,
      };

      messagesSlice.caseReducers.appendToMessages(state, reducerPayload);
      messagesSlice.caseReducers.updateInteractionSummariesWithNewMessage(
        state,
        reducerPayload,
      );
    });
    builder.addCase(markMsgsRead.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const reducerPayload = {
        payload: action.payload,
        type: action.type,
      };

      messagesSlice.caseReducers.updateInteractionSummariesWithReadMsgs(
        state,
        reducerPayload,
      );
      messagesSlice.caseReducers.updateSelectedInteractionMessagesWithReadMsgs(
        state,
        reducerPayload,
      );
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      if (state.selectedInteraction?.contact) {
        state.selectedInteraction.contact = action.payload;
      }

      const reducerPayload = {
        payload: action.payload,
        type: action.type,
      };

      messagesSlice.caseReducers.updateInteractionSummariesWithContact(
        state,
        reducerPayload,
      );
    });
    builder.addCase(configureNewAPIToken.fulfilled, (state, action) => {
      if (action.payload?.interactionSummaries) {
        state.interactionSummaries = action.payload.interactionSummaries;
      }
    });
  },
});

// __________APIs__________
export const getInteractionSummaries = createAsyncThunk(
  "messages/getInteractionSummaries",
  async (_, { dispatch }) => {
    const interactionSummaries = await dispatch(
      apiGet({ url: "/api/e/messages/interaction-summaries" }),
    );

    if (interactionSummaries) {
      return interactionSummaries.payload as InteractionSummary[];
    }

    return [];
  },
);

export const setSelectedInteraction = createAsyncThunk<
  SetSelectedInteractionResponse | null,
  string,
  { rejectValue: { message: string } }
>(
  "messages/setSelectedInteraction",
  async (selectedContactID: string, { dispatch }) => {
    const messages = await dispatch(
      apiGet({ url: `/api/e/messages/contact/${selectedContactID}` }),
    );

    const contact = await dispatch(
      apiGet({ url: `/api/e/contacts/${selectedContactID}` }),
    );

    if (!messages.payload || !contact.payload) {
      return null;
    }

    const res: SetSelectedInteractionResponse = {
      messages: messages.payload as MessageWithSenderInitials[],
      contact: contact.payload as Contact,
    };

    return res;
  },
);

export const sendMessage = createAsyncThunk<
  MessageWithSenderInitials | null,
  string,
  { rejectValue: { message: string } }
>("messages/sendMessage", async (msgBody: string, { dispatch, getState }) => {
  const rootState = getState() as RootState;
  const { selectedInteraction } = rootState.messages;
  const fromPhoneNumber = rootState.auth.myBusiness?.phoneNumber;

  if (!selectedInteraction) {
    // this should never happen but needs to be handled better than this
    return null;
  } else if (!fromPhoneNumber) {
    return null;
  }

  const { contact } = selectedInteraction;

  const msg: UncreatedMessage = {
    fromNum: fromPhoneNumber,
    toNum: contact.phoneNumber,
    body: msgBody,
  };

  const apiRes = await dispatch(
    apiPost({ url: "/api/s/messaging/sender", body: msg }),
  );

  const createdMsg = apiRes.payload as MessageWithSenderInitials;

  if (createdMsg) {
    const { me } = rootState.auth;
    createdMsg.senderInitials = getNameInitials(me?.firstName, me?.lastName);
  }

  return createdMsg;
});

export const markMsgsRead = createAsyncThunk<
  Message[] | null,
  Message[],
  { rejectValue: { message: string } }
>("messages/markMsgsRead", async (readMsgs: Message[], { dispatch }) => {
  const msgIDs = readMsgs.map((e) => e.ID);

  await dispatch(
    apiPut({ url: "api/e/messages/mark-read", body: { msgs: msgIDs } }),
  );

  return readMsgs;
});

export const updateContact = createAsyncThunk<
  Contact | null,
  { id: string; update: ContactUpdate },
  { rejectValue: { message: string } }
>(
  "messages/updateContact",
  async (payload: { id: string; update: ContactUpdate }, { dispatch }) => {
    const updatedContact = await dispatch(
      apiPut({
        url: `/api/e/contacts/${payload.id}`,
        body: payload.update,
      }),
    );

    return updatedContact.payload as Contact;
  },
);

export const getDisplayMessages = (
  state: MessagesState,
): MessageDisplayItem[] => {
  const { selectedInteraction } = state;

  if (!selectedInteraction) {
    return [];
  }

  const contactPhoneNumber = selectedInteraction.contact?.phoneNumber;
  const rawMessages = selectedInteraction.messages;

  let msgs: MessageDisplayItem[] = [];

  if (rawMessages && rawMessages.length > 0) {
    const firstMsg = rawMessages[0];
    const firstMsgDate = moment(firstMsg.createdAt).local();
    msgs.push({
      type: MessageDisplayTemplateOptions.DateDivider,
      date: firstMsgDate.toISOString(),
    });

    for (let i = 0; i < rawMessages.length; i++) {
      let currMsg = rawMessages[i];

      const msgType =
        currMsg.fromNum === contactPhoneNumber
          ? MessageDisplayTemplateOptions.SentByContact
          : MessageDisplayTemplateOptions.SentByEmployee;

      msgs.push({
        type: msgType,
        ...currMsg,
      });

      if (i < rawMessages.length - 1) {
        const nextMsg = rawMessages[i + 1];

        const currMsgDate = moment(currMsg.createdAt).local();
        const nextMsgDate = moment(nextMsg.createdAt).local();

        if (!currMsgDate.isSame(nextMsgDate, "day")) {
          msgs.push({
            type: MessageDisplayTemplateOptions.DateDivider,
            date: nextMsgDate.toISOString(),
          });
        }
      }
    }
  }

  return msgs;
};

export const getNumUnreadMessages = (state: MessagesState): number => {
  if (!state.interactionSummaries) {
    return 0;
  } else {
    return state.interactionSummaries?.reduce(
      (sum, item) => sum + item.numUnread,
      0,
    );
  }
};

export type DisplayMessages = ReturnType<typeof getDisplayMessages>;

// __________general exports__________
export const {
  setInteractionSummaries,
  updateInteractionSummariesWithReadMsgs,
} = messagesSlice.actions;
export default messagesSlice.reducer;
