import { TypographyOptions } from "@mui/material/styles/createTypography";

export default function getThemeTypography(): TypographyOptions {
  return {
    fontFamily: "SequelSans",
    h6: {
      fontSize: "0.9rem",
      letterSpacing: "0.06rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.0rem",
      letterSpacing: "0.05rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "SequelSansBold",
      letterSpacing: ".125rem",
      fontSize: "2rem",
      fontWeight: 700,
    },
    h1: {
      fontFamily: "SequelSansBold",
      letterSpacing: ".125rem",
      fontSize: "2.5rem",
      fontWeight: 900,
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.334em",
    },
    body2: {
      fontWeight: 400,
      lineHeight: "1.5em",
    },
    button: {
      textTransform: "none",
    },
  };
}
