import { Box, Button, Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

import NotificationCard from "./NotificationCard";
import { useAppDispatch, useAppSelector } from "@/app/state/hooks";
import {
  deleteNotificationByID,
  getNotifications,
  markNotificationReadByID,
  markNotificationUnreadByID,
} from "./slice";
import PageHeaderText from "@/components/text/PageHeaderText";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import RefreshButton from "@/components/buttons/RefreshButton";

const Notifications: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notifications } = useAppSelector((state) => state.notifications);

  const width = `min(100%, 700px)`;
  const headerHeight = "50px";

  if (!notifications) {
    return <></>;
  }

  return (
    <FullPageBase
      sx={{
        alignItems: "center",
      }}
    >
      <Stack
        direction="column"
        sx={{
          justifyContent: "center",

          alignItems: "center",
        }}
      >
        <Stack
          direction="row"
          sx={{
            width,
            justifyContent: "space-between",
            alignItems: "center",
            height: headerHeight,
          }}
        >
          <PageHeaderText>Notifications</PageHeaderText>

          <Box sx={{ display: "flex" }}>
            <RefreshButton onClick={() => dispatch(getNotifications())} />

            <Button
              onClick={() => navigate(-1)}
              variant="text"
              size="small"
              sx={{
                color: "text.secondary",

                "&:hover": {
                  color: "text.primary",
                  bgcolor: "transparent",
                },
              }}
            >
              <Typography sx={{ fontSize: 15 }}>Back</Typography>
            </Button>
          </Box>
        </Stack>

        {notifications.length > 0 ? (
          <Stack
            direction="column"
            sx={{
              width: width,
              height: `calc(100% - ${headerHeight})`,
              overflowY: "auto",
            }}
          >
            {notifications.map((el) => {
              return (
                <NotificationCard
                  key={el.ID}
                  item={el}
                  onMarkReadBtnClick={(e) => {
                    if (e) {
                      e.stopPropagation();
                    }
                    dispatch(markNotificationReadByID(el.ID));
                  }}
                  onMarkUnreadBtnClick={(e) => {
                    e.stopPropagation();

                    dispatch(markNotificationUnreadByID(el.ID));
                  }}
                  onDeleteBtnClick={(e) => {
                    e.stopPropagation();
                    dispatch(deleteNotificationByID(el.ID));
                  }}
                  sx={{ width: "100%", mb: 1 }}
                />
              );
            })}
          </Stack>
        ) : (
          <Box sx={{ width }}>
            <Typography
              sx={{
                pt: 2,
                color: "text.secondary",
                fontSize: 15,
                fontStyle: "italic",
              }}
            >
              No notifications
            </Typography>
          </Box>
        )}
      </Stack>
    </FullPageBase>
  );
};

export default Notifications;
