import {
  Box,
  Button,
  Stack,
  SvgIconTypeMap,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface IAppTabProps {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  iconSx?: SxProps<Theme>;
  isSelected?: boolean;
  onClick: () => void;
}

const AppTab: React.FC<IAppTabProps> = (props) => {
  const { isSelected, icon: Icon, iconSx, title, onClick } = props;

  return (
    <Box>
      <Button
        onClick={() => onClick()}
        disableRipple
        sx={{
          bgcolor: isSelected ? "text.primary" : "grey.100",
          color: isSelected ? "grey.100" : "text.primary",
          borderRadius: "10px 10px 0 0",
          border: (theme) =>
            `1px solid ${
              isSelected ? theme.palette.text.primary : theme.palette.grey[200]
            }`,
          "&:hover": {
            bgcolor: isSelected ? "text.primary" : "grey.200",
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            px: 1,
            color: "inherit",
          }}
        >
          <Icon sx={{ fontSize: 18, ...iconSx }} />

          <Typography sx={{ ml: "8px", fontWeight: "bold" }}>
            {title}
          </Typography>
        </Stack>
      </Button>
    </Box>
  );
};

export default AppTab;
