import { PropsWithChildren } from "react";
import { Box, Stack, SxProps, Theme } from "@mui/material";

export interface IFullPageBaseProps {
  children: string | JSX.Element | JSX.Element[] | boolean | any[];
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

const FullPageBase: React.FunctionComponent<
  PropsWithChildren<IFullPageBaseProps>
> = (props) => {
  return (
    <Stack
      direction="row"
      sx={{
        px: "20px",
        py: "20px",
        height: "100%",
        width: " 100%",

        overflowY: "auto",
        justifyContent: "center",
        alignItems: "center",

        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: props.fullWidth ? "100%" : "min(100%, 1200px)",
        }}
      >
        {props.children}
        <Box sx={{ height: "10px" }} />
      </Box>
    </Stack>
  );
};

export default FullPageBase;
