import { useParams } from "react-router-dom";

import queryString from "query-string";
import useSWR from "swr";

import { IDateStatAggregate, IStoreLocation } from "@alex/types";

import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import { BackButton } from "@/components/buttons";
import TabletStatusGraph from "./tabletStatusGraph";
import VisitsByHourGraph from "./visitsByHourGraph";
import PageHeaderText from "@/components/text/PageHeaderText";

const LocationDetails: React.FC = () => {
  const backLinkOverride = queryString.parse(window.location.search)?.backlink;

  const { id: locationID } = useParams();

  const { data: location } = useSWR<IStoreLocation>(
    locationID && `api/s/instore-capture/locations/${locationID}`,
  );

  const { data: heartbeats } = useSWR<IDateStatAggregate[]>(
    locationID && `api/s/instore-capture/locations/${locationID}/heartbeats`,
  );

  const { data: visits } = useSWR<IDateStatAggregate[]>(
    locationID && `api/s/instore-capture/locations/${locationID}/visits`,
  );

  if (!location) {
    return <></>;
  }

  return (
    <FullPageBase>
      <BackButton sx={{ mb: 1 }}>
        {backLinkOverride === "/" ? "Back to Dashboard" : "Back to Locations"}
      </BackButton>

      <PageHeaderText small>{location.name}</PageHeaderText>

      <VisitsByHourGraph data={visits} />

      <TabletStatusGraph data={heartbeats} />
    </FullPageBase>
  );
};

export default LocationDetails;
