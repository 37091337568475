import { IStoreLocation, IUncreatedStoreLocation } from "@alex/types";
import { useContext, useState } from "react";
import { ILocationFormProps } from ".";
import { notEmptyValidator } from "@/shared/utils/validators";
import { AppContext } from "@/app/context/AppContext";
import { useAppDispatch } from "@/app/state/hooks";
import { AxiosError, HttpStatusCode } from "axios";
import { addNewToast } from "@/services/ui/uiSlice";

interface LocationUpserterState {
  save: () => Promise<boolean>;
  reset: () => void;
  location: ILocationFormProps;
}

const useLocationUpsert = (): LocationUpserterState => {
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>();
  const [nameErrorMsg, setNameErrorMsg] = useState<string | undefined>();

  function getIsValid(): boolean {
    const isValidRes = notEmptyValidator(name, setNameErrorMsg);

    return isValidRes.isValid;
  }

  function reset() {
    setNameErrorMsg(undefined);
    setName("");
  }

  async function save(): Promise<boolean> {
    const isValid = getIsValid();

    if (!isValid) {
      return false;
    }

    try {
      // TODO - fix below
      const apiRes = await appContext?.api.post<IStoreLocation>(
        "/api/s/instore-capture/locations",
        { name },
      );

      dispatch(
        addNewToast({
          text: "Saved!",
          severity: "success",
        }),
      );

      reset();
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === HttpStatusCode.Forbidden) {
          dispatch(
            addNewToast({
              severity: "error",
              text: "Fix errors, then resubmit",
            }),
          );

          setNameErrorMsg("Name already exists!");
        }
      }
      console.error(e);
      return false;
    }

    return true;
  }

  return {
    location: {
      name: {
        value: name,
        onChange: (e) => setName(e.target.value),
        errorMsg: nameErrorMsg,
        onBlur: () => notEmptyValidator(name, setNameErrorMsg),
      },
    },
    reset,
    save,
  };
};

export default useLocationUpsert;
