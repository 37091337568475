import { Box, Typography, Stack, LinearProgress } from "@mui/material";

import { CancelButton, SaveButton } from "../buttons";
import AlexGradientText from "../text/AlexGradientText";
import { useAppSelector } from "@/app/state/hooks";
import { AppModal, CloseModalButton } from "../modals";
import ModalDivider from "../modals/shared/ModalDivider";

export interface IFormWrapperProps {
  isUpdate?: boolean;
  headerText: string;
  secondaryHeaderText?: string;
  onSaveBtnClick: () => void;
  onCancelBtnClick: () => void;
  children: JSX.Element | JSX.Element[];
  hideFooter?: boolean;
  confirmButtonOverride?: JSX.Element;
}

const FormWrapper: React.FunctionComponent<IFormWrapperProps> = (props) => {
  const pendingApiReqCount = useAppSelector((state) => state.api.pendingCount);

  return (
    <AppModal
      open={true}
      onClose={props.onCancelBtnClick}
      sx={{ width: "40%" }}
    >
      {/* HEADER */}
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",

          alignItems: "center",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "flex-start" }}>
          <Typography
            variant="h3"
            sx={{ color: "text.primary", fontWeight: "bold" }}
          >
            {props.headerText}

            {props.secondaryHeaderText?.trim() && ":"}
          </Typography>

          {props.secondaryHeaderText && (
            <AlexGradientText variant="h3" bold secondaryColor sx={{ ml: 1 }}>
              {props.secondaryHeaderText}
            </AlexGradientText>
          )}
        </Stack>
      </Stack>

      <Box sx={{ my: 1, height: "3px" }}>
        {pendingApiReqCount > 0 ? (
          <LinearProgress sx={{ borderRadius: "20px", height: "1px" }} />
        ) : (
          <ModalDivider />
        )}
      </Box>

      {/* BODY */}
      <Stack spacing={2} sx={{ py: 2 }}>
        {props.children}
      </Stack>

      {/* FOOTER */}
      {!props.hideFooter && (
        <Box>
          <Stack
            direction="row"
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CancelButton onClick={props.onCancelBtnClick} />
            <Box sx={{ pl: 1 }}></Box>

            {props.confirmButtonOverride || (
              <SaveButton
                onClick={props.onSaveBtnClick}
                isUpdate={props.isUpdate}
              />
            )}
          </Stack>
        </Box>
      )}
    </AppModal>
  );
};

export default FormWrapper;
