import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Sidebar from "./sidebar";
import Header from "./header";
import ToastsList from "@/services/ui/toasts/ToastsList";
import OnRouteChange from "@/app/routes/OnRouteChange";

const SIDEBAR_WIDTH = "240px";
const HEADER_HEIGHT = "55px";

const MainLayout = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundAttachment: "fixed",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          padding: `${HEADER_HEIGHT} 0px 0px 0px`,
        }}
      >
        <Header sidebarWidth={SIDEBAR_WIDTH} headerHeight={HEADER_HEIGHT} />

        <Sidebar
          onLogoClick={() => {
            navigate("/");
          }}
          sidebarWidth={SIDEBAR_WIDTH}
          headerHeight={HEADER_HEIGHT}
        />
        <CssBaseline />

        <Box
          sx={{
            height: `calc(100vh - ${HEADER_HEIGHT})`,
            width: `calc(100vw - ${SIDEBAR_WIDTH})`,
            ml: SIDEBAR_WIDTH,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Outlet />
        </Box>

        {/* UTILITIES */}
        <ToastsList />
        <OnRouteChange />
      </Box>
    </Box>
  );
};

export default MainLayout;
