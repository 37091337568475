import { Box, Divider, Stack, SxProps, Theme } from "@mui/material";

interface IAppTabGroupProps {
  children: JSX.Element[];
  sx?: SxProps<Theme>;
}

const AppTabGroup: React.FC<IAppTabGroupProps> = (props) => {
  return (
    <Box sx={{ ...props.sx }}>
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
        {props.children}
      </Stack>
      <Divider sx={{ borderBottomColor: "grey.300" }} />
    </Box>
  );
};

export default AppTabGroup;
