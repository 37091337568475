import { Modal, Box, SxProps, Theme } from "@mui/material";

import CloseModalButton from "./CloseModalButton";

export interface IAppModalProps {
  open: boolean;
  children: JSX.Element | JSX.Element[] | any;
  onClose?: () => void;
  sx?: SxProps<Theme>;
}

const AppModal: React.FunctionComponent<IAppModalProps> = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          borderRadius: "15px",
          bgcolor: "background.paper",
          outline: "none",
          transform: "translate(-50%, -50%)",
          py: 5,
          px: 8,
          ...props.sx,
        }}
      >
        <CloseModalButton
          onClick={() => props.onClose?.()}
          sx={{ position: "absolute", top: 10, right: 10 }}
        />
        {props.children}
      </Box>
    </Modal>
  );
};

export default AppModal;
