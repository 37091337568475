import {
  TextField,
  Box,
  SxProps,
  Theme,
  Typography,
  TextFieldProps,
} from "@mui/material";
import { useState, useEffect, HTMLInputTypeAttribute } from "react";

import { ErrorText, LabelText, sharedConfig, errorHeight } from "./shared";

// thought - could eventually change errorMsg to ValidationResponse to accomodate success messages as well
export interface IAppTextFieldProps {
  label?: string;
  value: string | number | undefined | null;
  disabled?: boolean;
  multiline?: boolean;
  errorMsg?: string;
  width?: string;
  height?: string;
  tooltipText?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => void;
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  autoFocus?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  characterLimit?: number;
  placeholder?: string;
  sx?: SxProps<Theme>;
}

const AppTextField: React.FunctionComponent<IAppTextFieldProps> = (props) => {
  const fieldWidth = props.width || sharedConfig.width;

  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setHasError(props?.errorMsg?.length ? true : false);
  }, [props.errorMsg]);

  return (
    <Box sx={{ ...props.sx }}>
      {props.label && (
        <LabelText tooltipText={props.tooltipText}>{props.label}</LabelText>
      )}

      {props.characterLimit && (
        <Typography sx={{ my: 1, fontSize: 13, color: "text.secondary" }}>
          {props.characterLimit} character limit.
          {props.value &&
            ` (${
              props.characterLimit - (props.value as string)?.length
            } left.)`}
        </Typography>
      )}

      <TextField
        placeholder={props.placeholder || props.label}
        value={props.value || ""}
        onChange={(e) => {
          e.preventDefault();

          if (
            !props.characterLimit ||
            e.target.value.length <= props.characterLimit
          ) {
            props.onChange?.(e);
          }
        }}
        onBlur={(e) => {
          e.preventDefault();
          props.onBlur?.(e);
        }}
        onFocus={(e) => {
          e.preventDefault();
          props.onFocus?.(e);
        }}
        onKeyDown={(e) => {
          props.onKeyDown?.(e);
        }}
        onKeyUp={(e) => {
          props.onKeyUp?.(e);
        }}
        inputRef={props.inputRef}
        autoFocus={props.autoFocus}
        type={props.type}
        multiline={props.multiline}
        disabled={props.disabled}
        autoComplete="off"
        error={hasError}
        minRows={props.multiline ? 2 : 1}
        sx={{
          borderRadius: sharedConfig.borderRadius,
          bgcolor: sharedConfig.bgcolor,
          width: fieldWidth,

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: hasError ? 1 : 0,
            borderRadius: sharedConfig.borderRadius,
          },
          "& .MuiOutlinedInput-input": {
            py: "0px",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "0px",
              boxShadow: (theme) => `inset 0 0 3px ${theme.palette.grey[200]}`,
            },
        }}
        InputProps={{
          sx: {
            height: props.height,
            py: sharedConfig.py,
            fontSize: sharedConfig.fontSize,
          },
        }}
      />

      {hasError ? (
        <ErrorText text={props.errorMsg} sx={{ height: errorHeight }} />
      ) : (
        <Box sx={{ height: errorHeight }} />
      )}
    </Box>
  );
};

export default AppTextField;
