import { useState, useEffect } from "react";

import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import PageHeaderText from "@/components/text/PageHeaderText";
import { useAppSelector, useAppDispatch } from "@/app/state/hooks";
import { AppTextField } from "@/components/inputs";
import { Box, Stack } from "@mui/material";
import { SaveButton } from "@/components/buttons";
import { updateMe } from "../settings/slice";
import { addNewToast } from "@/services/ui/uiSlice";

const Profile: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { me } = useAppSelector((state) => state.auth);

  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    setEmailAddress(me?.emailAddress || "");
    setFirstName(me?.firstName || "");
    setLastName(me?.lastName || "");
  }, [me]);

  async function handleSaveBtnClick() {
    const update = {
      firstName,
      lastName,
    };

    await dispatch(updateMe(update));

    dispatch(
      addNewToast({
        text: "Successfully updated your profile",
        severity: "success",
      }),
    );
  }

  if (!me) {
    return <Box />;
  }
  return (
    <FullPageBase>
      <PageHeaderText>Profile</PageHeaderText>

      <AppTextField
        label="Email Address"
        value={emailAddress}
        disabled
        sx={{ mt: 2 }}
        width="40ch"
      />

      <Stack direction="row" sx={{ alignItems: "center", my: 1 }}>
        <AppTextField
          label="First Name"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          sx={{ mr: "5px" }}
          width="20ch"
        />

        <AppTextField
          label="Last Name"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          width="calc(20ch - 5px)"
        />
      </Stack>

      <Box>
        <SaveButton onClick={handleSaveBtnClick} />
      </Box>
    </FullPageBase>
  );
};

export default Profile;
