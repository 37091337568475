import { SxProps, Theme, Typography } from "@mui/material";

export interface IPageHeaderTextProps {
  children: any;
  sx?: SxProps<Theme>;
  small?: boolean;
}

const PageHeaderText: React.FunctionComponent<IPageHeaderTextProps> = (
  props,
) => {
  const variant = props.small ? "h2" : "h1";

  return (
    <Typography
      variant={variant}
      sx={{
        fontWeight: "bold",
        textShadow: (theme) =>
          `0.2px 0.2px 0.2px ${theme.palette.text.secondary}`,
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default PageHeaderText;
