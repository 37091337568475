import { useState } from "react";
import { Stack, SxProps, Theme } from "@mui/material";

import { getPrimaryGradient } from "@/shared/utils/ui";

export interface IGradientButtonProps {
  onClick: () => void;
  children?: string | string[];
  icon?: JSX.Element;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const GradientButton: React.FunctionComponent<IGradientButtonProps> = (
  props,
) => {
  const [isActive, setIsActive] = useState(false);

  function handleBtnClick() {
    setIsActive(false);
    props.onClick();
  }

  return (
    <Stack
      direction="row"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={() => {
        if (!props.disabled) {
          handleBtnClick();
        }
      }}
      sx={{
        background: getPrimaryGradient(160),
        color: "white",
        alignItems: "center",
        py: 0.75,
        px: 2,
        borderRadius: "100px",
        width: "fit-content",
        ...(isActive &&
          !props.disabled && {
            boxShadow: (theme) => `0px 1px 1px ${theme.palette.primary.main}`,
          }),
        fontWeight: "bold",
        height: "34px",
        "&:hover": props.disabled
          ? {}
          : {
              cursor: "pointer",
            },
        opacity: props.disabled ? 0.2 : "none",
        ...props.sx,
      }}
    >
      {props.icon && (
        <Stack sx={{ mr: 1, alignItems: "center" }}>{props.icon}</Stack>
      )}

      {props.children}
    </Stack>
  );
};

export default GradientButton;
