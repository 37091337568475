import axios, { AxiosRequestConfig } from "axios";

import config from "@/config";

export interface ApiState {
  post: <T>(url: string, body: any) => Promise<T>;
}

const useApi = (apiKey?: string): ApiState => {
  function getAxiosConfig(apiToken: string): AxiosRequestConfig {
    return { headers: { Authorization: `Bearer ${apiToken}` } };
  }

  const api = axios.create({
    baseURL: config.api.baseURL,
  });

  async function apiPost<T>(url: string, body: any): Promise<T> {
    if (apiKey) {
      const apiRes = await api.post<T>(url, body, getAxiosConfig(apiKey));

      return apiRes.data;
    }

    return {} as T;
  }

  return { post: apiPost };
};

export default useApi;
