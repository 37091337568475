import { TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";

import { Contact } from "@alex/types";

import {
  IBaseTableRowProps,
  ActionsTableCell,
  AppTableCell,
  ClickableTableCell,
} from "@/components/tables";
import { getPhoneNumberDisplay } from "@/shared/utils/formatters";

export interface IContactRowProps extends Contact, IBaseTableRowProps {}

export const ContactRow: React.FunctionComponent<IContactRowProps> = (
  props,
) => {
  const { firstName, lastName } = props;

  return (
    <TableRow key={props.ID}>
      {firstName || lastName ? (
        <ClickableTableCell
          onClick={() => {
            props.onEntityClick(props.ID);
          }}
        >
          {[firstName, lastName].join(" ")}
        </ClickableTableCell>
      ) : (
        <TableCell>
          <Typography sx={{ color: "text.secondary", fontStyle: "italic" }}>
            No name
          </Typography>
        </TableCell>
      )}

      <ClickableTableCell
        onClick={() => {
          props.onEntityClick(props.ID);
        }}
      >
        {getPhoneNumberDisplay(props.phoneNumber)}
      </ClickableTableCell>

      <AppTableCell>{props.comments ? props.comments : ""}</AppTableCell>

      <AppTableCell>{props.isOptedOut}</AppTableCell>

      <AppTableCell>
        {moment(props.createdAt).local().format("ddd, MMM D, yyyy")}
      </AppTableCell>

      <ActionsTableCell
        onClick={(e) => {
          if (props.openActionsPopover) {
            props.openActionsPopover(props.ID, props.firstName, e);
          }
        }}
      />
    </TableRow>
  );
};
