import { Button, Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import config from "@/config";
import { useAppSelector } from "@/app/state/hooks";

const HeaderCenter: React.FunctionComponent = () => {
  const { hasConnectionError } = useAppSelector((state) => state.api);
  const { getAccessTokenSilently } = useAuth0();

  async function handleRetry() {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.auth0.audience,
          scope: "",
        },
      });

      if (accessToken) {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      {hasConnectionError && (
        <Stack
          direction="row"
          sx={{
            bgcolor: "error.light",
            color: "error.main",
            py: 0.5,
            pl: 3,
            border: ".5px solid red",
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 20, mr: 1 }} />
          Connection Error
          <Button
            onClick={handleRetry}
            size="small"
            color="error"
            variant="contained"
            sx={{ ml: 3, mr: 1 }}
          >
            Retry
          </Button>
        </Stack>
      )}
    </>
  );
};

export default HeaderCenter;
