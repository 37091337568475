import { useState } from "react";
import { Stack, SxProps, Theme } from "@mui/material";

import { getPrimaryGradient } from "@/shared/utils/ui";

export interface IOutlinedGradientButtonProps {
  onClick: () => void;
  children?: string | string[];
  icon?: JSX.Element;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const OutlinedGradientButton: React.FunctionComponent<
  IOutlinedGradientButtonProps
> = (props) => {
  const [isActive, setIsActive] = useState(false);

  function handleBtnClick() {
    setIsActive(false);
    props.onClick();
  }

  return (
    <Stack
      direction="row"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={() => {
        if (!props.disabled) {
          handleBtnClick();
        }
      }}
      sx={{
        background: getPrimaryGradient(160),
        alignItems: "center",
        p: "1.5px",
        borderRadius: "100px",
        width: "fit-content",
        ...(isActive &&
          !props.disabled && {
            boxShadow: (theme) => `0px 1px 1px ${theme.palette.primary.main}`,
          }),

        "&:hover": props.disabled
          ? {}
          : {
              cursor: "pointer",
            },
        opacity: props.disabled ? 0.2 : "none",
        ...props.sx,
      }}
    >
      <Stack
        direction="row"
        sx={{
          bgcolor: "white",
          fontWeight: "bold",
          color: "text.secondary",
          borderRadius: "100px",
          height: "32px",
          px: 2,

          alignItems: "center",
        }}
      >
        {props.icon && (
          <Stack sx={{ mr: 1, alignItems: "center" }}>{props.icon}</Stack>
        )}

        {props.children}
      </Stack>
    </Stack>
  );
};

export default OutlinedGradientButton;
