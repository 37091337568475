import { Stack } from "@mui/material";

import { AppTextField } from "@/components/inputs";
import ImagePreview from "./ImagePreview";
import { FormElementState } from "@/shared";

export interface ITabletScreensSettingsStateProps {
  logoImageUrl: FormElementState<string | undefined>;
  bodyImageUrl: FormElementState<string | undefined>;
  title: FormElementState<string | undefined>;
  subTitle: FormElementState<string | undefined>;
  minutesBetweenVisits: FormElementState<number | undefined>;
  updatedAt?: Date;
}

const TabletScreensSettings: React.FC<ITabletScreensSettingsStateProps> = (
  props,
) => {
  return (
    <Stack>
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "45%", pt: 2 }} spacing={1}>
          <AppTextField
            {...props.logoImageUrl}
            label="Logo Image URL"
            placeholder=" "
          />

          <AppTextField
            {...props.bodyImageUrl}
            label="Body Image URL"
            placeholder=" "
          />

          <AppTextField
            {...props.title}
            label="Title"
            placeholder="Enter your phone number to earn free ..."
            multiline
            characterLimit={50}
          />

          <AppTextField
            {...props.subTitle}
            label="Sub Title"
            placeholder="You will earn a FREE reward with just 10 stars!"
            multiline
            characterLimit={80}
          />

          {/* <AppTextField
            {...props.minutesBetweenVisits}
            label="Minutes Between Visits"
            width="10ch"
            placeholder="180"
            type="number"
            tooltipText="This is the amount of time between valid visits. 180 minutes (3 hours) is a common choice."
          /> */}
        </Stack>

        <Stack
          sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}` }}
        >
          <ImagePreview
            logoImageUrl={props.logoImageUrl.value}
            bodyImageUrl={props.bodyImageUrl.value}
            sx={{
              ml: 1,
              mt: 1,
              // boxShadow: (theme) => `-1px 2px 5px ${theme.palette.grey[400]}`,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TabletScreensSettings;
