import { useState, useEffect, useCallback } from "react";

import { Contact } from "@alex/types";

import { IBaseFormProps, FormWrapper } from "@/components/forms";
import { AppTextField } from "@/components/inputs";
import { useAppDispatch } from "@/app/state/hooks";
import { ValidationResponse } from "@/shared";
import { notEmptyValidator, validateNotEmpty } from "@/shared/utils/validators";
import { addNewToast } from "@/services/ui/uiSlice";
import {
  createContact,
  formatAndValidatePhoneNumber,
  getContactByID,
  updateContactByID,
} from "../slice";

const DEFAULT_HEADER_TEXT = "Create a Contact";

export interface IContactsFormProps extends IBaseFormProps {
  contactID?: string;
}

const ContactsForm: React.FunctionComponent<IContactsFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { contactID } = props;

  const isUpdate = !!contactID;

  const [headerText, setHeaderText] = useState(DEFAULT_HEADER_TEXT);
  const [secondaryHeaderText, setSecondaryHeaderText] = useState<
    string | undefined
  >();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [comments, setComments] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState<
    string | undefined
  >();
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState<
    string | undefined
  >();
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (props.contactID) {
      dispatch(getContactByID(props.contactID)).then((res) => {
        if (res.payload) {
          const savedContact = res.payload as Contact;
          setFirstName(savedContact.firstName);
          setLastName(savedContact.lastName);
          setComments(savedContact.comments);
          setPhoneNumber(savedContact.phoneNumber);

          setHeaderText("Edit Contact");
          setSecondaryHeaderText(
            `${savedContact.firstName} ${savedContact.lastName}`,
          );
        } else {
          setHeaderText(DEFAULT_HEADER_TEXT);
          setSecondaryHeaderText(undefined);
        }
      });
    }
  }, [dispatch, props.contactID]);

  const validatePhoneNumber =
    useCallback(async (): Promise<ValidationResponse> => {
      const emptyRes = validateNotEmpty(phoneNumber);

      if (!emptyRes.isValid) {
        setPhoneNumberErrorMsg(emptyRes.errorMsg || "");
        return emptyRes;
      }

      const isValidRes = await dispatch(
        formatAndValidatePhoneNumber(phoneNumber),
      );

      const isValid = isValidRes.payload as boolean;

      const errorMsg = isValid ? "" : "invalid or duplicate phone number";
      setPhoneNumberErrorMsg(errorMsg);

      return {
        isValid,
        errorMsg,
      };
    }, [phoneNumber, dispatch]);

  async function handleSaveBtnClick() {
    if (isUpdate) {
      await dispatch(
        updateContactByID({
          id: contactID,
          update: {
            firstName,
            lastName,
            comments,
          },
        }),
      );

      dispatch(
        addNewToast({
          text: "Updated contact",
          severity: "success",
        }),
      );
    } else {
      const firstNameValid = notEmptyValidator(firstName, setFirstNameErrorMsg);
      const lastNameValid = notEmptyValidator(lastName, setLastNameErrorMsg);
      const phoneNumValid = await validatePhoneNumber();

      if (
        !firstNameValid.isValid ||
        !lastNameValid.isValid ||
        !phoneNumValid.isValid
      ) {
        dispatch(
          addNewToast({
            text: "Error: One or more fields contain invalid data",
            severity: "error",
          }),
        );

        return;
      }

      await dispatch(
        createContact({
          phoneNumber,
          firstName,
          lastName,
          comments,
        }),
      );

      dispatch(
        addNewToast({
          text: "Created contact",
          severity: "success",
        }),
      );
    }

    props.onClose();
  }

  return (
    <FormWrapper
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      onSaveBtnClick={handleSaveBtnClick}
      onCancelBtnClick={props.onClose}
      isUpdate={isUpdate}
    >
      <AppTextField
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onBlur={(e) => notEmptyValidator(firstName, setFirstNameErrorMsg)}
        errorMsg={firstNameErrorMsg}
      />

      <AppTextField
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onBlur={(e) => notEmptyValidator(lastName, setLastNameErrorMsg)}
        errorMsg={lastNameErrorMsg}
      />

      <AppTextField
        label="Phone Number"
        value={phoneNumber}
        disabled={isUpdate}
        onChange={(e) => setPhoneNumber(e.target.value)}
        onBlur={(e) => {
          validatePhoneNumber();
        }}
        errorMsg={phoneNumberErrorMsg}
        tooltipText="This is the contact's phone number"
      />

      <AppTextField
        label="Comments"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        multiline
      />
    </FormWrapper>
  );
};

export default ContactsForm;
