import { LabelText } from "@/components/inputs/shared";
import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";

interface IImagePreviewProps {
  logoImageUrl?: string;
  bodyImageUrl?: string;
  sx?: SxProps<Theme>;
}

const ImagePreview: React.FC<IImagePreviewProps> = (props) => {
  return (
    <Stack
      sx={{
        bgcolor: "grey.100",
        pt: 3,
        pb: 5,
        px: 6,
        borderRadius: "20px",

        ...props.sx,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, color: "text.secondary" }}>
        Image Preview
      </Typography>
      <LabelText sx={{ color: "text.secondary" }}>Logo Image</LabelText>
      <Stack sx={{ mt: 1, height: "80px" }}>
        {props.logoImageUrl ? (
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "fit-content",
              borderRadius: "5px",
            }}
            src={props.logoImageUrl}
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              width: "150px",
              bgcolor: "grey.100",
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[300]} 100%)`,
              boxShadow: (theme) => `inset 0 0 5px ${theme.palette.grey[400]}`,
              borderRadius: "5px",
            }}
          />
        )}
      </Stack>
      <LabelText sx={{ mt: 4, color: "text.secondary" }}>Body Image</LabelText>
      <Stack
        sx={{
          height: "275px",
          width: "275px",
          mt: 1,
        }}
      >
        {props.bodyImageUrl ? (
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "200px",
            }}
            src={props.bodyImageUrl}
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "200px",
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[300]} 100%)`,
              boxShadow: (theme) => `inset 0 0 5px ${theme.palette.grey[400]}`,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ImagePreview;
