import { useState, useEffect } from "react";
import { IconButton, Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";

import { AppRoute } from "@/app/routes";
import { useAppSelector } from "@/app/state/hooks";

export interface INotificationsProps {}

const Notifications: React.FunctionComponent<INotificationsProps> = (props) => {
  const navigate = useNavigate();
  const { notifications } = useAppSelector((state) => state.notifications);

  const [numUnread, setNumUnread] = useState(0);

  useEffect(() => {
    setNumUnread(
      notifications.reduce(
        (numUnread, el) => numUnread + (el.isRead ? 0 : 1),
        0,
      ),
    );
  }, [notifications]);

  return (
    <IconButton
      onClick={() => navigate(AppRoute.Notifications)}
      sx={{
        "&:hover": { color: "text.secondary", bgcolor: "transparent" },
        color: "grey.600",
      }}
    >
      <Badge
        badgeContent={numUnread}
        color="error"
        sx={{
          "& .MuiBadge-badge": { right: 1, top: 1, fontSize: 12, p: 0 },
        }}
      >
        <NotificationsIcon sx={{ fontSize: 24 }} />
      </Badge>
    </IconButton>
  );
};

export default Notifications;
