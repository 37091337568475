import { Button, Typography } from "@mui/material";

import { MessageIcon } from "@/components/icons";

export interface IMessageContactActionProps {
  onClick: () => void;
}

const MessageContactAction: React.FunctionComponent<
  IMessageContactActionProps
> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        color: "text.secondary",
        justifyContent: "left",
        borderRadius: "5px",
        width: "100%",
      }}
    >
      <MessageIcon width={16} height={16} sx={{ mr: 1 }} />
      <Typography sx={{ fontSize: 14 }}>Message</Typography>
    </Button>
  );
};

export default MessageContactAction;
