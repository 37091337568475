import { Button, SxProps, Theme, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export interface IBackButtonProps {
  children?: string;
  sx?: SxProps<Theme>;
}

const BackButton: React.FunctionComponent<IBackButtonProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(-1)}
      sx={{
        color: "text.secondary",
        borderRadius: "25px",
        p: 0,
        "&:hover": {
          bgcolor: "transparent",
          color: "text.primary",
        },
        ...props.sx,
      }}
    >
      <ArrowBackIcon sx={{ fontSize: 16, mr: 0.25 }} />

      {props.children || "Back"}
    </Button>
  );
};

export default BackButton;
