import {
  Typography,
  IconButton,
  Tooltip,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface ILabelTextProps {
  children: string;
  tooltipText?: string;
  sx?: SxProps<Theme>;
}

const LabelText: React.FunctionComponent<ILabelTextProps> = (props) => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        height: "20px",
        color: "text.primary",
        ...props.sx,
      }}
    >
      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
        {props.children}
      </Typography>

      {props.tooltipText && (
        <Tooltip
          placement="right"
          title={
            <Typography sx={{ fontSize: 14, p: 0.5 }}>
              {props.tooltipText}
            </Typography>
          }
        >
          <IconButton disableRipple>
            <HelpOutlineIcon sx={{ fontSize: 18, mx: -0.5 }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default LabelText;
