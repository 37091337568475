import { useState } from "react";

import { Contact } from "@alex/types";

import { IContactRowProps, ContactRow } from "./ContactRow";
import {
  IBaseTableProps,
  AppTable,
  TableActionsPopover,
  ViewDetailsAction,
  NoRowsRow,
} from "@/components/tables";

export interface IContactsTableProps extends IBaseTableProps {
  rows: Contact[] | undefined | null;
}

const ContactsTable: React.FunctionComponent<IContactsTableProps> = ({
  rows,
  onEntityClick,
  ...props
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [focused, setFocused] = useState<{ id: string; name: string } | null>(
    null,
  );

  function openActionsPopover(
    id: string,
    name: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
    setFocused({ id, name });
  }

  function closePopover() {
    setIsPopoverOpen(false);
    setAnchorEl(null);
  }

  function handleViewDetailsBtnClick() {
    if (focused) {
      onEntityClick(focused.id);
      closePopover();
    }
  }

  return (
    <>
      <AppTable
        headerText="Contacts"
        entityName="Contact"
        subHeader=""
        searchKeys={["firstName", "lastName", "phoneNumber"]}
        columnLabels={[
          "Name",
          "Phone Number",
          "Comments",
          "Opted Out",
          "Created At",
          "",
        ]}
        rows={rows?.map((row) => {
          let rowProps: IContactRowProps = {
            ...row,
            onEntityClick,
            openActionsPopover,
          };
          return rowProps;
        })}
        rowComponent={ContactRow}
        noRowsComponent={
          <NoRowsRow onClick={props.onCreateBtnClick} pluralEntity="contacts" />
        }
        onEntityClick={onEntityClick}
        onRefreshBtnClick={props.onRefreshBtnClick}
        onCreateBtnClick={props.onCreateBtnClick}
        sx={{ height: "100%", width: "100%" }}
      />

      <TableActionsPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
      >
        <ViewDetailsAction onClick={handleViewDetailsBtnClick} />
      </TableActionsPopover>
    </>
  );
};

export default ContactsTable;
