import { Box } from "@mui/material";

import SearchBar from "@/components/tables/internal/SearchBar";

const HeaderLeft: React.FunctionComponent = () => {
  return (
    <Box>
      {/* <SearchBar
            placeholder="Search keywords, contacts, campaigns"
            width="42ch"
            fontSize={15}
            sx={{ height: '36px' }}
          /> */}
    </Box>
  );
};

export default HeaderLeft;
