import { Stack } from "@mui/material";

import { AppTextField } from "@/components/inputs";
import { FormElementState } from "@/shared";

export interface ILocationFormProps {
  name: FormElementState<string | undefined>;
}

const LocationForm: React.FC<ILocationFormProps> = (props) => {
  return (
    <Stack>
      <AppTextField {...props.name} label="Name" placeholder=" " width="40ch" />
    </Stack>
  );
};

export default LocationForm;
