import { Stack, SxProps, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface ICloseModalButtonProps {
  onClick: () => void;
  sx?: SxProps<Theme>;
}

const CloseModalButton: React.FunctionComponent<ICloseModalButtonProps> = (
  props,
) => {
  return (
    <Stack
      onClick={props.onClick}
      sx={{
        bgcolor: "grey.200",
        borderRadius: "100%",
        p: 0.5,
        "&:hover": {
          color: "text.primary",
          cursor: "pointer",
          // bgcolor: "grey.400",
          boxShadow: (theme) => `0 0 2px ${theme.palette.grey[600]}`,
        },
        color: "text.secondary",
        ...props.sx,
      }}
    >
      <CloseIcon sx={{ fontSize: 16 }} />
    </Stack>
  );
};

export default CloseModalButton;
